import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { GET_ALL_SETTING, POST_UPDATE_SETTING } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ChangePassword = props => {

    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + '?type=email-setting');
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);

        var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
        if (res_data.status) {
            await get_app_setting();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'email_enable') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>IV Music | Email Setting</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div class="card-header">
                            <h6 className='m-0'>Email Setting</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Email Otp Limit</label>
                                    <input name="email_otp_limit" value={form_inputs.email_otp_limit} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Host Name</label>
                                    <input name="email_host" value={form_inputs.email_host} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Port</label>
                                    <input name="email_port" value={form_inputs.email_port} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">User Name</label>
                                    <input name="email_user_name" value={form_inputs.email_user_name} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Password</label>
                                    <input name="email_password" value={form_inputs.email_password} onChange={handleInputChange} className="form-control" type="text" />
                                </div>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-4">
                                            <label className="form-label text-capitalize mt-1">Email Enable</label>
                                        </div>
                                        <div className="col-8">
                                            <label class="switch">
                                                <input name="email_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.email_enable)} type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update</button>
                                    <Link to="/setting" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
