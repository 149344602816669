import React from "react"

const Loader = props => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <div role="status" class="ms-2 spinner-border text-dark"><span class="sr-only">Loading...</span></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Loader
