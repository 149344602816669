import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//Admin
import AdminList from "./pages/Admin/AdminList";
import AdminForm from "./pages/Admin/AdminForm";

//User
import UserList from "./pages/User/UserList";

//Category
import CategoryList from "./pages/Categories/CategoryList";
import CategoryForm from "./pages/Categories/CategoryForm";

//Tags
import TagsList from "./pages/Tags/TagsList";
import TagsForm from "./pages/Tags/TagsForm";

//Tags
import ProductList from "./pages/Product/ProductList";
import ProductForm from "./pages/Product/ProductForm";

// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import CommonSetting from "./pages/Setting/CommonSetting";




function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                {/* admin */}
                <UserRoute exact path="/admin" component={AdminList} layout={Default} />
                <UserRoute exact path="/admin/add" component={AdminForm} layout={Default} />
                <UserRoute exact path="/admin/edit/:id?" component={AdminForm} layout={Default} />
                
                {/* user */}
                <UserRoute exact path="/users" component={UserList} layout={Default} />

                {/* categories */}
                <UserRoute exact path="/categories" component={CategoryList} layout={Default} />
                <UserRoute exact path="/categories/add" component={CategoryForm} layout={Default} />
                <UserRoute exact path="/categories/edit/:id?" component={CategoryForm} layout={Default} />
                
                {/* Tags */}
                <UserRoute exact path="/tags" component={TagsList} layout={Default} />
                <UserRoute exact path="/tags/add" component={TagsForm} layout={Default} />
                <UserRoute exact path="/tags/edit/:id?" component={TagsForm} layout={Default} />
                
                {/* Product */}
                <UserRoute exact path="/product" component={ProductList} layout={Default} />
                <UserRoute exact path="/product/add" component={ProductForm} layout={Default} />
                <UserRoute exact path="/product/edit/:id?" component={ProductForm} layout={Default} />

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/common" component={CommonSetting} layout={Default} />

                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
