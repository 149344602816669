const getToken = () => {
    return localStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('user_detail'))? JSON.parse(localStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if(localStorage.getItem('user_detail')){
        return JSON.parse(localStorage.getItem('user_detail'))['user_role_id'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_detail');
}

const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const img_view = (img , width = '' , height = '') => {
    return process.env.REACT_APP_IMG_URL+'img?img='+img+'&w='+width+'&h='+height
}

export { img_view,amountFormat, getUserType,getToken, removeUserSession, setUserSession, is_login,getUserDetail, getUserId };