
// auth
export const POST_LOGIN = "/auth/login"
export const POST_VERIFY_OTP = "/auth/verify-otp"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"

//category
export const POST_CATEGORY_LIST = process.env.REACT_APP_API_URL+"category/get-all-category"
export const DELETE_CATEGORY = "/category/delete"
export const POST_UPDATE_CATEGORY_STATUS = "/category/update-status"
export const POST_ADD_EDIT_CATEGORY = "/category/add-edit"
export const POST_GET_CATEGORY_DETAIL = "/category/detail"

//tags
export const POST_TAGS_LIST = process.env.REACT_APP_API_URL+"tags/get-all-tags"
export const DELETE_TAGS = "/tags/delete"
export const POST_UPDATE_TAGS_STATUS = "/tags/update-status"
export const POST_ADD_EDIT_TAGS = "/tags/add-edit"
export const POST_GET_TAGS_DETAIL = "/tags/detail"

//tags
export const POST_PRODUCT_LIST = process.env.REACT_APP_API_URL+"product/get-all-product"
export const DELETE_PRODUCT = "/product/delete"
export const POST_UPDATE_PRODUCT_STATUS = "/product/update-status"
// export const POST_ADD_EDIT_TAGS = "/tags/add-edit"
// export const POST_GET_TAGS_DETAIL = "/tags/detail"






// common
export const GET_STATE = "/common/get-country-based-states"
export const GET_CITY = "/common/get-states-based-city"
export const GET_DISTRIBUTOR_LIST = "/common/get-distributor"
export const GET_RETAILER_LIST = "/common/get-retailer"



//admin
export const POST_ADMIN_USER_LIST = process.env.REACT_APP_API_URL+"admin/get-all-user"
export const DELETE_ADMIN_USER = "/admin/delete"
export const POST_UPDATE_ADMIN_USER_STATUS = "/admin/update-status"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/detail"

//user
export const POST_USER_LIST = process.env.REACT_APP_API_URL+"user/get-all-user"
export const DELETE_USER = "/user/delete"
export const POST_UPDATE_USER_STATUS = "/user/update-status"

//states
export const POST_STATES_LIST = process.env.REACT_APP_API_URL+"states/get-all-states"
export const DELETE_STATES = "/states/delete"
export const POST_UPDATE_STATES_STATUS = "/states/update-status"
export const POST_ADD_EDIT_STATE = "/states/add-edit"
export const POST_GET_STATE_DETAIL = "/states/detail"

//cities
export const POST_CITIES_LIST = process.env.REACT_APP_API_URL+"cities/get-all-city"
export const DELETE_CITIES = "/cities/delete"
export const POST_UPDATE_CITIES_STATUS = "/cities/update-status"
export const POST_ADD_EDIT_CITIES = "/cities/add-edit"
export const POST_GET_CITIES_DETAIL = "/cities/detail"
export const POST_GET_CITIES_FORM_OPTION = "/cities/get-city-form-option"

//service
export const POST_GET_ALL_SERVICE_LIST = process.env.REACT_APP_API_URL+"service/get-all-service"
export const POST_UPDATE_SERVICE_STATUS = "/service/update-service"

//complain
export const POST_GET_ALL_COMPLAIN = process.env.REACT_APP_API_URL+"complain/get-all-complain"
export const POST_COMPLAIN_DETAIL = "/complain/detail"
export const POST_COMPLAIN_REPLAY = "/complain/replay"

//report
export const POST_REPORT_MONEY_TRANSFER = process.env.REACT_APP_API_URL+"report/get-all-transfer"
export const POST_TRANSFER_DETAIL = "/report/detail"

// recharge
export const POST_GET_ALL_RECHARGE_LIST = process.env.REACT_APP_API_URL+"wallet-recharge/get-all-get-recharge"
export const POST_GET_RECHARGE_DETAIL = "/wallet-recharge/detail"
export const POST_RECHARGE = "/wallet-recharge/recharge"
