import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

  return (
    <div className='left_nav_bar'>
      <ul className='sidebar-menu'>
        <li>
          <NavLink exact to="/" className="has-arrow">
            <FeatherIcon icon="home" width="22" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" >
            <FeatherIcon icon="users" width="22" />
            <span>User</span>
          </a>
          <ul class="collapse list-unstyled" id="homeSubmenu">
            <li>
              {/* <NavLink to="/admin">Admin User</NavLink> */}
              <NavLink to="/users">Users</NavLink>
            </li>
          </ul>
        </li>

        <li>
          <NavLink to="/categories" >
            <i class="fa fa-th-large mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
            <span>Categories</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/tags" >
            <i class="fa fa-tags mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
            <span>Tags</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/product" >
            <i class="fa fa-cubes mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
            <span>Productes</span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink exact to="/complain" className="has-arrow">
            <FeatherIcon icon="message-square" width="22" className="mt-1" />
            <span>Complain</span>
          </NavLink>
        </li> */}
        {/* <li>
          <a href="#ReportSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
            <FeatherIcon icon="bar-chart" width="22" />
            <span style={{ 'marginTop': '2px' }}>Report</span>
          </a>
          <ul className="collapse list-unstyled" id="ReportSubmenu">
            <li>
              <NavLink to="/report/money-transfer">Money Transfer</NavLink>
              <NavLink to="/report/electricity">Electricity Bill</NavLink>
              <NavLink to="/report/gas">Gas Bill</NavLink>
              <NavLink to="/report/dth">DTH Recharge</NavLink>
              <NavLink to="/report/prepaid">Prepaid Recharge</NavLink>
              <NavLink to="/report/postpaid">Postpaid Recharge</NavLink>
            </li>
          </ul>
        </li> */}
        <li>
          <NavLink exact to="/setting" className="has-arrow">
            <FeatherIcon icon="settings" width="22" className="mt-1" />
            <span>Setting</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}